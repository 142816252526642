//加入新班级的弹框
<template>
  <div class="join_class_dialog">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      :title="title"
      :width="width"
    >
      <template v-slot:content>
        <div class="join_class_dialog_content">
          <!-- 提示的内容部分 -->
          <div class="top">
            <el-input
              maxlength="20"
              placeholder="请输入班级邀请码"
              v-model="classInviteCode"
              clearable
            />
          </div>
          <!-- 底部的取消和确定按钮 -->
          <div class="bom_action">
            <!-- 取消 -->
            <div class="cancel" @click.stop="close">
              <span>取</span><span style="margin-left: 16px">消</span>
            </div>
            <!-- 确定 -->
            <el-button
              :loading="showCommitLoading"
              :class="['commit']"
              @click.stop="doCommitAction"
            >
              <span>确</span><span style="margin-left: 16px">定</span>
            </el-button>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";
export default {
  data() {
    return {
      //宽度
      width: this.dialogWidth,
      //是否显示确定按钮加载框的标识
      showCommitLoading: false,
      //加入的班级邀请码
      classInviteCode: "",
    };
  },
  created() {},
  methods: {
    //关闭弹框
    close() {
      this.classInviteCode = "";
      //触发父组件的关闭方法
      this.$emit("close");
    },

    //确定的操作
    doCommitAction() {
      if (this.isEmpty(this.classInviteCode)) {
        this.showWarnMsg("请输入班级邀请码");
      } else {
        this.showCommitLoading = true;
        this.$emit("joinClass", this.classInviteCode);
      }
    },
  },
  computed: {},
  components: {
    CommonDialog,
  },
  props: ["showDialog", "title"],
};
</script>
<style lang="scss" scoped>
.join_class_dialog {
  .join_class_dialog_content {
    display: flex;
    justify-content: center;
    color: black;
    font-size: 14px;
    flex-direction: column;

    //顶部部分
    .top {
      display: flex;
      justify-content: center;
      ::v-deep .el-input {
        width: 90%;
        border: none;
      }

      //左右间距
      ::v-deep .el-input__inner {
        padding: 0 10px;
      }

      //获取焦点的颜色
      ::v-deep .el-input__inner:focus {
        border-color: #dcdfe6;
      }

      //移入时的颜色
      ::v-deep .el-input__inner:hover {
        border-color: #dcdfe6;
      }

      // .el-input__inner {
      //   border-color: #c0c4cc;
      // }

      // ::v-deep .el-input.is-active .el-input__inner,
      // .el-input__inner:focus {
      // }
    }

    //底部的操作按钮
    .bom_action {
      margin-top: 20px;
      height: 48px;
      display: flex;
      justify-content: center;

      .cancel,
      .commit {
        width: 120px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }

      .cancel {
        background: #dcdcdc;
        margin-right: 30px;
        color: black;
      }

      .commit {
        background: $common_bg;
        color: white;
      }
    }
  }
}
</style>