//通用的弹框组件
<template>
  <el-dialog :visible.sync="showDialog" :width="width" :close-on-click-modal="false" :before-close="handleClose">
    <!-- 标题 -->
    <div class="top">
      <span class="com_title">{{ title }}</span>
      <i class="iconfont cloud-guanbi" @click.stop="close"></i>
    </div>
    <!-- 插槽部分 -->
    <slot name="content"></slot>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    close() {
      this.$emit("close");
    },

    handleClose(done) {
      this.close();
    }
  },
  computed: {},
  components: {},
  props: ["showDialog", "title", "width"]
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding-bottom: 10px;
  margin: 0 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::v-deep .el-dialog__header {
  padding: 0;
  display: flex;
  visibility: hidden;
}

::v-deep .el-dialog__body {
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  margin: 12px 0;
  // height: 58px;
  align-items: center;
  position: relative;

  .com_title {
    color: black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14px;
    font-size: 16px;
    font-weight: bold;
  }

  .cloud-guanbi {
    position: absolute;
    cursor: pointer;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
