//班级消息
<template>
  <div class="class_message_dialog">
    <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width">
      <template v-slot:content>
        <div class="class_message_dialog_content">
          <!-- 内容部分 -->
          <div v-if="!showLoading">
            <!-- 老师端消息 -->
            <div class="messge_content" v-if="role == 2">
              <div class="item_class_msg" v-for="(item, index) in currMsg" :key="index">
                <!-- 时间 -->
                <span>{{ item.CREATE_TIME | dataFormat(that) }}</span>

                <!-- 老师处理学生申请加入班级 -->
                <div class="teacher_msg_action" v-if="item.CLASS_STATUS == 1 || item.CLASS_STATUS == 2 || item.CLASS_STATUS == 0">
                  <span class="user_name"
                    >用户&nbsp;<span style="color: #1094fd">{{ item.NAME }}</span
                    >&nbsp;申请加入班级</span
                  >

                  <!-- 操作部分 -->
                  <div class="action">
                    <!-- 已同意 -->
                    <div v-if="item.CLASS_STATUS == 1" class="has_agreement">
                      已同意
                    </div>
                    <!-- 已拒绝 -->
                    <div v-else-if="item.CLASS_STATUS == 2" class="has_reject">
                      已拒绝
                    </div>
                    <!-- 未处理 -->
                    <div v-else-if="item.CLASS_STATUS == 0" class="untreated">
                      <span class="bt_reject" @click.stop="doRejectAction(item)">拒绝</span>
                      <span class="bt_agreement" @click.stop="doAgreement(item)">同意</span>
                    </div>
                  </div>
                </div>

                <!-- 老师创建班级 -->
                <div class="margin_sty" v-if="item.CLASS_STATUS == 3">
                  你成功创建班级
                </div>

                <!-- 老师端移除班级 -->
                <div v-if="item.CLASS_STATUS == 4">
                  <div class="user_name">
                    {{ item.NAME[0] }}将&nbsp;<span class="del_stu_names" :title="item.NAME[1]">{{ item.NAME[1] }}</span
                    >&nbsp;移出{{ item.NAME[2] }}
                  </div>
                </div>

                <!-- 老师创建班级 -->
                <div class="margin_sty" v-if="item.CLASS_STATUS == 5">
                  你修改了班级名称
                </div>
              </div>
            </div>

            <!-- 学生端消息 -->
            <div class="messge_content" v-if="role == 0">
              <div class="item_class_msg" v-for="(item, index) in currMsg" :key="index">
                <!-- 时间 -->
                <span>{{ item.CREATE_TIME | dataFormat(that) }}</span>

                <!-- 消息内容 -->
                <div class="msg_content">
                  <div>
                    <!-- 已同意 -->
                    <div v-if="item.CLASS_STATUS == 1">
                      你的班级申请已通过！
                    </div>
                    <!-- 已拒绝 -->
                    <div v-else-if="item.CLASS_STATUS == 2">
                      你的班级申请已被拒绝,如果有疑问请联系班主任！
                    </div>
                    <div v-else-if="item.CLASS_STATUS == 4">
                      你已被班主任移出班级，如果有疑问请联系班主任！
                    </div>
                    <div v-else-if="item.CLASS_STATUS == 5">
                      班主任修改了班级名称！
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 底部的取消和确定按钮 -->
            <div class="bom_action">
              <!-- 上一页 -->
              <button :class="['cancel', { cursor_unclick: currPage == 0 }]" @click.stop="doPreAction" :disabled="currPage == 0">
                <span>{{ currPage == 0 ? "第一页" : "上一页" }}</span>
              </button>
              <!-- 确定 -->
              <button :class="['commit', { cursor_unclick: currPage == totalPage - 1 }]" @click.stop="doNextAction" :disabled="currPage == totalPage - 1">
                <span>{{ currPage == totalPage - 1 ? "末 页" : "下一页" }}</span>
              </button>
            </div>
          </div>

          <!-- 加载框部分 -->
          <LoadingMsg v-else></LoadingMsg>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";

//引入Loading框
import LoadingMsg from "@/components/LoadingMsg";

export default {
  data() {
    return {
      //是否显示加载框
      showLoading: true,

      //用户角色 0学生,1老师
      role: -1,

      //对话框的宽度
      width: "580px",

      //每显示的消息条数
      pageNum: 4,

      //当前显示的页面
      currPage: 0,

      //总的页码数量
      totalPage: 0,

      //定义this
      that: this,

      //服务器返回的班级消息
      message: [],

      //当前消息分组的数组
      msgGroup: [],

      //当前页面需要显示的消息数据
      currMsg: []
    };
  },
  created() {
    //获取用户的角色
    this.role = this.getUserRole();
  },

  mounted() {
    //请求消息数据
    this.httpData();
  },
  methods: {
    //获取班级下的所有消息
    async httpData() {
      let param = this.getHttpParams();
      if (this.role == 0 || this.role == -1) {
        //学生
        param.type = 4;
      } else if (this.role == 2) {
        //老师
        param.type = 3;
      }
      param.cid = this.cid;
      param.page = 1;
      //这里模拟一次性把数据请求完成,暂定一次性请求1500条数据
      param.num = 1500;
      let res = await this.$http.fetchPost(this.role == 2 ? this.$api.TEACHER_CLASS_MANAGER : this.$api.STUDENT_CLASS_MSG, param);

      if (res.data.state == 200) {
        //改变加载状态
        this.showLoading = false;

        //获取班级的消息数据
        this.message = res.data.list;

        //组装消息数据
        this.sysClassData(this.message);
      } else {
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },

    //组装消息数据
    sysClassData(msgArr) {
      //先清空一下分组的数据以及当前显示消息的数组的数据
      this.msgGroup.splice(0, this.msgGroup.length);
      this.currMsg.splice(0, this.currMsg.length);

      for (let i = 0, len = msgArr.length; i < len; i += this.pageNum) {
        this.msgGroup.push(msgArr.slice(i, i + this.pageNum));
      }

      //总的消息页
      this.totalPage = this.msgGroup.length;

      //配置当前需要显示的学生数据,默认是第一页的数据
      this.msgGroup[0].forEach(item => {
        this.currMsg.push(item);
      });
    },

    //拒绝加入班级的操作
    doRejectAction(item) {
      this.http(item, 1);
    },

    //同意加入班级的操作
    doAgreement(item) {
      this.http(item, 2);
    },

    //发送请求给服务器做拒绝和同意的操作
    async http(item, flag) {
      //获取登录用户
      let userInfo = this.getUserInfo();
      let param = this.getHttpParams();
      param.type = 4;
      //老师的id
      param.uid = item.USER_ID2;
      //学生id
      param.stuid = item.USER_ID;
      param.schoolId = userInfo.school_id;
      param.cid = this.cid;
      if (flag == 1) {
        //拒绝,同步条目的处理状态
        param.state = 2;
      } else if (flag == 2) {
        //同意
        param.state = 1;
      }
      let res = await this.$http.fetchPost(this.$api.TEACHER_CLASS_MANAGER, param);
      if (res.data.state == 200) {
        //操作成功
        if (flag == 1) {
          //拒绝
          item.CLASS_STATUS = 2;
        } else if (flag == 2) {
          //同意
          item.CLASS_STATUS = 1;
        }
        //关闭当前弹框
        this.close();
        //通知父组件的页面刷新
        this.$emit("refreshData");
      } else {
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //上一页
    doPreAction() {
      this.currPage -= 1;
      //同步页面数据
      this.sysPageMsgList();
    },

    //下一页
    doNextAction() {
      this.currPage += 1;
      //同步页面数据
      this.sysPageMsgList();
    },

    //根据当前页面同步消息显示的数据
    sysPageMsgList() {
      //先清空当前消息列表数据
      this.currMsg.splice(0, this.currMsg.length);

      //配置当前需要显示的学生数据,默认是第一页的数据
      this.msgGroup[this.currPage].forEach(item => {
        this.currMsg.push(item);
      });
    }
  },
  //过滤器
  filters: {
    dataFormat(date, that) {
      return that.formatDate(date, "yyyy-MM-dd HH:mm");
    }
  },
  computed: {},
  components: {
    CommonDialog,
    LoadingMsg
  },
  props: ["showDialog", "title", "cid"]
};
</script>
<style lang="scss" scoped>
.class_message_dialog {
  ::v-deep .el-dialog {
    top: 50%;
  }
  .class_message_dialog_content {
    min-height: 284px;
    display: flex;
    justify-content: center;
    color: black;
    font-size: 14px;
    flex-direction: column;

    //顶部部分
    .messge_content {
      min-height: 200px;
      justify-items: center;
      display: flex;
      flex-direction: column;
      margin: 0 16px;
      //消息条目
      .item_class_msg {
        display: flex;
        height: 50px;
        align-items: center;
        color: black;
        font-size: 12px;

        .user_name {
          margin-left: 12px;
          display: flex;
          .del_stu_names {
            max-width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #1094fd;
          }
        }
        .margin_sty {
          margin-left: 12px;
        }

        .time {
          width: 110px;
        }

        //老师端消息
        .teacher_msg_action {
          display: flex;
        }

        .msg_content {
          flex: 1;
          margin-left: 16px;
        }

        //已同意
        .has_agreement {
          color: $common_bg;
          margin-left: 2px;
        }

        //已拒绝
        .has_reject {
          color: #e81525;
          margin-left: 2px;
        }

        //未处理
        .untreated {
          display: flex;
          .bt_reject,
          .bt_agreement {
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .bt_reject {
            color: $text_gray;
            border: 1px solid $common_bg;
            height: 20px;
            width: 36px;
            margin: 0 12px;
          }

          .bt_agreement {
            color: white;
            background: $common_bg;
            height: 22px;
            width: 38px;
          }
        }
      }
    }

    //底部的操作按钮
    .bom_action {
      margin-top: 20px;
      height: 48px;
      display: flex;
      justify-content: center;

      .cancel,
      .commit {
        border: none;
        width: 120px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }

      .cancel {
        background: #dcdcdc;
        margin-right: 30px;
        color: black;
      }

      .cursor_click {
        cursor: pointer;
      }

      .cursor_unclick {
        cursor: not-allowed;
      }

      .commit {
        background: $common_bg;
        color: white;
      }
    }
  }
}
</style>
