//学生端我的班级
<template>
    <div class="student_my_class">
        <div class="content" v-if="!showLoading">
            <!-- 顶部的班级管理功能 -->
            <div class="top_info">
                <!-- 左边的班级管理和班级信息 -->
                <div class="class_info">
                    <div class="text_class">我的班级</div>
                </div>

                <!-- 右边的加入新班级 -->
                <div class="create_class" @click.stop="joinClassDialog">加入新班级</div>
            </div>

            <!-- 显示内容的部分 -->
            <div class="show_content">
                <!-- 班级列表部分 -->
                <div class="show_class_list" v-if="isShowContent">
                    <div class="item_class" v-for="(item, index) in classData" :key="index">
                        <div class="item_class_content">
                            <!-- 顶部,班级名,人数,复制码等 -->
                            <div class="top">
                                <!-- 班级名 -->
                                <span class="class_name">班级名：{{ item.classname
                                }}&nbsp;&nbsp;({{ item.totalNum }}&nbsp;人)</span>

                                <!-- 班级id -->
                                <span class="class_id">ID：{{ item.class_id }}</span>

                                <!-- 班级老师 -->
                                <span class="class_teacher">班级老师：{{ item.teacher_name }}</span>

                                <!-- 占位 -->
                                <span class="place_holder"></span>

                                <!-- 消息 -->
                                <span class="iconfont cloud-xiaoxizhongxin" @click.stop="showClassMesage(item)"></span>

                                <!-- 消息数量 -->
                                <span class="message_num">({{ item.infoNum ? item.infoNum : 0 }})</span>
                            </div>
                            <!-- 中间显示班级学生的部分 -->
                            <div class="mid">
                                <!-- 显示学生的分组数据 -->
                                <div class="show_student_list" v-if="item.totalPage != 0">
                                    <!-- 左边的分页按钮 -->
                                    <button type="button" :class="['left_btn', { btn_disable: item.currPage == 1 }]"
                                        v-if="item.totalPage > 1" :disabled="item.currPage == 1"
                                        @click.stop="showPreStuent(item)">
                                        <i class="el-icon el-icon-arrow-left"></i>
                                    </button>
                                    <!-- 学生列表展示部分 -->
                                    <div class="show_student_content">
                                        <div class="item_stuent_info" v-for="(student, pos) in item.currStudentArr"
                                            :key="pos">
                                            <!-- 头像 -->
                                            <div class="head">
                                                <img class="stu_img animated fadeIn" :src="student.user_img" />
                                            </div>
                                            <!-- 姓名 -->
                                            <div class="stu_name" :title="student.name">
                                                {{ student.name }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 右边的分页按钮 -->
                                    <button type="button" @click.stop="showNextStuent(item)" :class="[
                                        'right_btn',
                                        { btn_disable: item.currPage == item.totalPage },
                                    ]" v-if="item.totalPage > 1" :disabled="item.currPage == item.totalPage">
                                        <i class="el-icon el-icon-arrow-right"></i>
                                    </button>
                                </div>
                                <!-- 没有学生的空视图 -->
                                <div class="empty_styent" v-else>该班级暂无学生~</div>
                            </div>
                            <!-- 底部显示操作部分 班级有学生时才展示 -->
                            <div class="bom" v-if="item.totalPage != 0">
                                <!-- 显示班级人数 -->
                                <span>{{ item.currPage }}/{{ item.totalPage }}</span>
                                <!-- 占位 -->
                                <div class="placholder_view"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 空视图部分 -->
                <!-- <div class="class_empty" v-else>暂无班级,请申请加入班级吧~</div> -->
                <el-empty description="暂无班级,请申请加入班级吧~" v-else></el-empty>
            </div>
        </div>

        <!-- 加载框 -->
        <Loading v-if="showLoading"></Loading>

        <!-- 加入新班级的弹框 -->
        <JoinNewClass ref="joinclass" @close="closeJoinClassDialog" :showDialog="showJoinClassDialog"
            :title="joinClassTitle" @joinClass="joinClass" />

        <!-- 班级消息 -->
        <ClassMessage v-if="this.showClassMsg" @close="closeClassMsgDialog" :showDialog="showClassMsg"
            :title="classMsgTitle" :cid="currClassObj.class_id" />
    </div>
</template>
<script>
//创建新班级
import JoinNewClass from "@/views/dialog/JoinNewClass";
//班级消息
import ClassMessage from "@/views/dialog/ClassMessage";
export default {
    data() {
        return {
            //加载框的操作
            showLoading: true,

            //当前操作的班级对象
            currClassObj: null,

            //有班级数据时控制的显示状态值
            isShowContent: true,

            //每个班级每页显示的学生数,默认是10个
            pageNum: 10,

            //加入班级的弹框标识
            showJoinClassDialog: false,

            //加入新班级的弹框标题
            joinClassTitle: "加入新班级",
            //班级消息
            classMsgTitle: "班级消息",

            //班级消息的弹框控制值
            showClassMsg: false,
            //班级数据
            classData: [],
        };
    },
    created() { },
    mounted() {
        //这里先需要请求服务器获取班级的数据,获取到班级数据后在进行数组组装
        this.httpData();
    },
    methods: {
        //获取学生端请求接口获取公共参数的方法
        getParams(type) {
            let param = this.getHttpParams();
            param.type = type;
            return param;
        },
        //请求班级数据
        async httpData() {
            let param = this.getParams(2);
            let res = await this.$http.fetchPost(
                this.$api.STUDENT_CLASS_MSG,
                param
            );
            if (res.data.state == 200) {
                //关闭Loading
                this.showLoading = false;

                let classInfo = res.data.classinfo;

                if (!this.isEmpty(classInfo) && classInfo.length > 0) {
                    //有班级
                    this.isShowContent = true;

                    //获取请求到的学校数据
                    this.classData = classInfo;

                    //在对学校数据进行组装
                    this.assemblyData();
                } else {
                    //没有班级
                    this.isShowContent = false;
                }
            } else {
                this.showErrorMsg(this.getMesage(res.data.state));
            }
        },

        //对每个班级的数据做分组处理
        assemblyData() {
            this.classData.forEach((item) => {
                this.sysClassData(item);
            });
        },

        //同步每个班级的数据
        sysClassData(item) {
            //配置是否显示全选,删除的控制值
            this.$set(item, "isShowAction", false);

            //配置全选和反选的标识
            this.$set(item, "showMsg", "全选");

            //配置当前页面
            if (!this.isEmpty(item.students) && item.students.length > 0) {
                //班级有学生
                //取出所有的学生
                let allStudent = item.students;

                //给每个学生赋值响应式的属性
                allStudent.forEach((student) => {
                    //配置是否选中的属性
                    this.$set(student, "isSelected", false);
                });

                //有学生
                this.$set(item, "currPage", 1);

                //配置分组的数据
                let studentGroup = [];
                for (
                    let i = 0, len = item.students.length;
                    i < len;
                    i += this.pageNum
                ) {
                    studentGroup.push(item.students.slice(i, i + this.pageNum));
                }

                this.$set(item, "studentGroup", studentGroup);

                //配置总页面数
                this.$set(item, "totalPage", item.studentGroup.length);

                //配置班级的人数
                this.$set(item, "totalNum", item.students.length);

                //配置当前需要显示的学生数据,默认是第一页的数据
                let currStudentArr = [];
                item.studentGroup[0].forEach((item) => {
                    currStudentArr.push(item);
                });

                this.$set(item, "currStudentArr", currStudentArr);
            } else {
                //没有学生
                this.$set(item, "currPage", 0);
                this.$set(item, "totalPage", 0);
                //配置班级的人数
                this.$set(item, "totalNum", 0);
            }
        },
        //切换上一页的学生
        showPreStuent(item) {
            //获取当前页面
            item.currPage = item.currPage - 1;

            if (item.currPage > 0) {
                this.sysCurrPageStudent(item);
            }
        },

        //切换下一页的学生
        showNextStuent(item) {
            //获取当前页面
            item.currPage = item.currPage + 1;

            if (item.currPage <= item.totalPage) {
                this.sysCurrPageStudent(item);
            }
        },

        //同步显示当前页面学生的状态
        sysCurrPageStudent(item) {
            //获取组的数据
            let groupSutent = item.studentGroup;

            //获取当前显示的组
            let currGroup = item.currStudentArr;

            //清空当前显示的组的数据
            currGroup.splice(0, currGroup.length);

            let showStuArr = [];

            //取出切换后页面的学生数据
            showStuArr = groupSutent[item.currPage - 1];

            //将学生数据放入当前页面显示的数组中
            showStuArr.forEach((item) => {
                currGroup.push(item);
            });
        },

        //加入新班级的操作
        joinClassDialog() {
            //判断当前班级的个数是否大于10个
            // if (this.classData.length > 10) {
            //   this.showWarnMsg("班级数量已达上限");
            // } else {
            //打开创建班级的弹框
            this.showJoinClassDialog = true;
            // }
        },

        //关闭创建新班级的弹框
        closeJoinClassDialog() {
            this.showJoinClassDialog = false;
        },

        //加入新班级
        joinClass(classInviteCode) {
            //请求加入新班级的请求
            this.doHttpJoinClass(classInviteCode);
        },

        //加入新班级的请求服务器操作
        async doHttpJoinClass(classInviteCode) {
            //加入新班级
            let param = this.getParams(1);

            //加入班级的邀请码
            param.code = classInviteCode;

            let res = await this.$http.fetchPost(
                this.$api.STUDENT_CLASS_MSG,
                param
            );

            if (res.data.state == 200) {
                //改变子组件的Loading
                this.$refs.joinclass.showCommitLoading = false;

                //创建班级成功后,关闭创建班级的弹框
                this.showJoinClassDialog = false;

                this.showSuccessMsg("你的申请已经发送,请等候老师的审批处理");
            } else {
                this.showErrorMsg(this.getMesage(res.data.state));
                //改变子组件的Loading
                this.$refs.joinclass.showCommitLoading = false;
            }
        },

        //显示班级消息
        showClassMesage(item) {
            //记录一下当前的班级对象
            // if (!this.isEmpty(item.infoNum) && item.infoNum != 0) {
            if (!this.isEmpty(item.allInfoNum) && item.allInfoNum != 0) {
                //证明有消息
                this.currClassObj = item;
                //将当前未读消息重置为0
                item.infoNum = 0;
                this.showClassMsg = true;
            } else {
                this.showWarnMsg("暂无消息~");
            }
        },

        //关闭班级消息弹框
        closeClassMsgDialog() {
            this.showClassMsg = false;
        },
    },
    computed: {},
    components: {
        JoinNewClass,
        ClassMessage,
    },
};
</script>
<style lang="scss" scoped>
.student_my_class {
    background: $common_gray;
    height: calc(100vh - 87px);
    overflow-y: auto;

    .content {
        height: 100%;
        width: $common_width;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        .top_info {
            margin-top: 30px;
            display: flex;
            align-items: center;

            .class_info {
                flex: 1;

                //班级管理
                .text_class {
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            // 加入新班级
            .create_class {
                cursor: pointer;
                color: white;
                font-size: 12px;
                width: 140px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $common_bg;
                border-radius: 20px;
            }
        }

        .show_content {
            flex: 1;
            margin-top: 20px;
            margin-bottom: 20px;
            position: relative;

            //显示班级列表以及空视图
            .show_class_list,
            .class_empty {
                position: absolute;
                height: 100%;
                width: 100%;
            }

            .show_class_list {
                overflow-y: auto;

                .item_class {
                    box-shadow: 0px 2px 0px rgba($color: #000000, $alpha: 0.2);
                    height: 200px;
                    margin-bottom: 20px;
                    width: 100%;
                    background: white;
                    border-radius: 8px;
                    font-size: 12px;
                    display: flex;

                    //条目内容
                    .item_class_content {
                        flex: 1;
                        margin: 10px;
                        margin-left: 16px;
                        margin-right: 16px;
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        //顶部部分
                        .top {
                            display: flex;
                            align-items: center;

                            .class_id,
                            .class_teacher {
                                margin-left: 12px;
                            }

                            .cloud-bianji {
                                font-size: 14px;
                                margin: 0 16px;
                                cursor: pointer;
                            }

                            .cloud-bianji:before {
                                vertical-align: sub;
                            }

                            .place_holder {
                                flex: 1;
                            }

                            //复制邀请码
                            .copy_code {
                                cursor: pointer;
                                margin: 0 16px;
                                height: 30px;
                                width: 100px;
                                display: flex;
                                align-items: center;
                                border-radius: 20px;
                                justify-content: center;
                                background: #fed420;
                            }

                            //消息
                            .cloud-xiaoxizhongxin {
                                margin-right: 4px;
                                color: $common_bg;
                                font-size: 14px;
                                cursor: pointer;
                            }

                            .cloud-xiaoxizhongxin:before {
                                vertical-align: sub;
                            }
                        }

                        //中间展示学生列表部分
                        .mid {
                            flex: 1;
                            position: relative;

                            .show_student_list,
                            .empty_styent {
                                position: absolute;
                                height: 100%;
                                width: 100%;
                            }

                            .empty_styent {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 14px;
                                color: $text_gray;
                            }

                            //展示学生的区域
                            .show_student_list {
                                display: flex;
                                align-items: center;

                                .left_btn,
                                .right_btn {
                                    cursor: pointer;
                                    background: #fff;
                                    border: none;
                                    width: 28px;
                                    height: 28px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }

                                //不允许点击时的禁用样式
                                .btn_disable {
                                    cursor: not-allowed;
                                }

                                .cloud-zuojiantou,
                                .cloud-youjiantou {
                                    color: $text_gray;
                                    font-size: 14px;
                                    cursor: pointer;
                                }

                                //展示学生的区域
                                .show_student_content {
                                    flex: 1;
                                    display: flex;

                                    .item_stuent_info {
                                        width: calc(100% / 10);
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        position: relative;

                                        //学生的头像部分
                                        .head {
                                            position: relative;

                                            .stu_select,
                                            .stu_unselect {
                                                cursor: pointer;
                                                width: 20px;
                                                height: 20px;
                                                object-fit: cover;
                                                top: -2px;
                                                left: 20px;
                                                position: absolute;
                                            }

                                            .stu_select {
                                                content: url("../../assets/img/selected.png");
                                            }

                                            .stu_unselect {
                                                content: url("../../assets/img/unselect.png");
                                            }

                                            //学生头像
                                            .stu_img {
                                                width: 66px;
                                                height: 66px;
                                                border-radius: 50%;
                                                object-fit: contain;
                                            }
                                        }

                                        //学生姓名
                                        .stu_name {
                                            margin-top: 4px;
                                            width: 40px;
                                            text-align: center;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                        }
                                    }
                                }
                            }
                        }

                        //底部的操作栏部分
                        .bom {
                            display: flex;
                            align-items: center;

                            //管理
                            .manager {
                                color: $common_bg;
                                cursor: pointer;
                                margin: 0 20px;
                            }

                            //全选,删除
                            .all_del {

                                .all,
                                .del {
                                    color: $common_bg;
                                    cursor: pointer;
                                }

                                .all {
                                    margin-right: 20px;
                                }
                            }

                            //占位符号
                            .placholder_view {
                                flex: 1;
                            }
                        }

                        //删除班级按钮
                        .cloud-lajitong {
                            cursor: pointer;
                            position: absolute;
                            right: 10px;
                            bottom: 0;
                        }
                    }
                }
            }

            .class_empty {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $text_gray;
                font-size: 16px;
            }

            //去除滚动条
            .show_class_list::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
</style>  